import React from "react"
import TripCard from "./tripCard"
import Grid from "@material-ui/core/Grid"

const TripsGrid = props => {
  const trips = props.trips.sort((a, b) => (a.start < b.start ? 1 : -1))

  return (
    <Grid container spacing={1}>
      {trips.map(trip => (
        <Grid item xs={12} sm={6} md={3} key={trip.fields.slug}>
          <TripCard trip={trip} />
        </Grid>
      ))}
    </Grid>
  )
}

export default TripsGrid
