// Gatsby supports TypeScript natively!
import React from "react"
import { useIntl } from "react-intl"
import { graphql } from "gatsby"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import PageHeader from "../components/elements/pageHeader"
import TripsGrid from "../components/tripsOverview/tripsGrid"
import Container from "@material-ui/core/Container"

const TripsPage = ({ data, location }) => {
  const intl = useIntl()

  const trips = data.trips.edges
    .map(({ node: trip }) => trip)
    //add title attribute for trips from mdx
    .map(trip => ({
      ...trip,
      title: data.tripsMdx.edges.find(
        n => n.node.fields.slug === trip.fields.slug
      ).node.frontmatter.title,
    }))

  return (
    <BaseStructure location={location}>
      <Seo title={intl.formatMessage({ id: "page.trips.title" })} />
      <Container className="standardWidth">
        <PageHeader title={intl.formatMessage({ id: "page.trips.title" })} />
        <TripsGrid trips={trips} />
      </Container>
    </BaseStructure>
  )
}

export default TripsPage

export const pageQuery = graphql`
  query allTrips($locale: String!) {
    trips: allTripsJsonJson {
      edges {
        node {
          fields {
            slug
          }
          start
          end
          countries
          featuredImage {
            imgurId
          }
        }
      }
    }
    tripsMdx: allMdx(filter: { fields: { locale: { eq: $locale } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
