import React from "react"
import { useLocalization } from "gatsby-theme-i18n"

import { imgurUrlFromId } from "../../utils/imgurService"
import { formatDateStd, localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "400px",
    height: "100%",
  },
  featuredImage: {
    objectPosition: "center center",
  },
  imgTop: {
    objectPosition: "top center !important",
  },
  imgBottom: {
    objectPosition: "bottom center !important",
  },
  date: {
    fontWeight: "100",
    fontSize: "0.9em",
    color: theme.palette.secondary.light,
  },
}))

const TripCard = props => {
  const classes = useStyles()
  const { locale, config } = useLocalization()
  const { trip } = props
  const { title, start, end, featuredImage } = trip
  const featuredImageAlignment = featuredImage.alignment
    ? featuredImage.alignment
    : false

  return (
    <Card className={classes.root}>
      <CardActionArea href={localizedLink("/trip" + trip.fields.slug, locale)}>
        <CardMedia
          className={
            classes.featuredImage +
            (featuredImageAlignment === "top" ? " " + classes.imgTop : "") +
            (featuredImageAlignment === "bottom" ? " " + classes.imgBottom : "")
          }
          component="img"
          alt={title}
          height="300"
          image={imgurUrlFromId(featuredImage.imgurId, "l")}
          title={title}
        />
        <CardContent>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            className={classes.date + " marginBottomS"}
          >
            {formatDateStd(start, locale, config)}
            {" - "}
            {formatDateStd(end, locale, config)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default TripCard
