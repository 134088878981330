/**
 * Get URL for image in specific size
 *
 * @param {String}   id             ID of image
 * @param {String}   [size = ""]    size of image, options:
 * crops to square:
 *   s = Small Square (90×90)
 *   b = Big Square (160×160)
 * resizes (in relation to larger side):
 *   t = Small Thumbnail (160)
 *   m = Medium Thumbnail (320)
 *   l = Large Thumbnail (640)
 *   h = Huge Thumbnail (1024)
 *
 * @return {String}  Imgur-URL
 */
export const imgurUrlFromId = (id, size = "") => ("https://i.imgur.com/" + id + size + ".jpg")

